import { useEventSearch } from '../../events/repositories/EventRepository.hook';
import { useEffect, useState } from 'react';
import { InfoContainer, ItemContainer, LabelContainer } from '../../common/components/PanelItem.shared';
import { AtdnText } from '@ataden/design-system';
import { EventAggregate } from '../../events/domain/EventAggregate';
import { SupportTicketComponent } from './SupportTicketComponent';

type UserSupportTicketsProps = {
  userId: string;
};

export const UserSupportTickets = ({ userId }: UserSupportTicketsProps) => {
  const eventSearch = useEventSearch();

  const [supportEvents, updateSupportEvents] = useState<EventAggregate[]>([]);

  useEffect(() => {
    eventSearch({
      aggregateId: userId,
      eventType: 'support:message_created',
    }).then(page => updateSupportEvents(page.items));

  }, [userId]);

  return (
        <ItemContainer>
            <InfoContainer>
                <LabelContainer>
                    <AtdnText type="important">Support message:</AtdnText>
                    {supportEvents.length === 0 ? (
                        <AtdnText>nothing</AtdnText>
                    ) : (
                        <>
                            {supportEvents.map((event) => (
                                <SupportTicketComponent key={event.eventId} eventId={event.eventId}
                                                        creationDate={event.createdDate}/>
                            ))}
                        </>
                    )}
                </LabelContainer>
            </InfoContainer>
        </ItemContainer>
  );
};
