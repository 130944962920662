export type Datacenter = {
  PUE: number;
  rackCost: number;
  bp100M: number;
  bp1G: number;
  bp10G: number;
};

export enum DeviceType {
  ROUTER,
  SWITCH,

  SERVER_COMPUTING,
  SERVER_STORAGE,
  SERVER_DATABASE,
}

export type Device = {
  type: DeviceType;
  price: number;
  heightU: number;
};

export type Router = Device & {};

export type Switch = Device & {};

export type ServerComputing = Device & {};

export type ServerStorage = Device & {
  storage: number;
};

export type ServerResult = {
  storageServerCount: number;
  computingServerCount: number;
  totalServerCost: number;
};

export type NetworkResult = {
  storageSwitchCount: number;
  computingSwitchCount: number;
  totalSwitchCost: number;
  bandwidthCost: number;
  networkCost: number;
};

export type RackResult = {
  rackCount: number;
  totalRackCost: number;
};

export type GeneralResult = {
  totalCost: number;
};

export type DatacenterResult = {
  serverResult: ServerResult;
  networkResult: NetworkResult;
  rackResult: RackResult;
  generalResult: GeneralResult;
};

export const emptyDatacenter = (): DatacenterResult => {
  return {
    serverResult: {
      storageServerCount: 0,
      computingServerCount: 0,
      totalServerCost: 0,
    },

    networkResult: {
      storageSwitchCount: 0,
      computingSwitchCount: 0,
      totalSwitchCost: 0,
      bandwidthCost: 0,
      networkCost: 0,
    },

    rackResult: {
      rackCount: 0,
      totalRackCost: 0,
    },

    generalResult: {
      totalCost: 0,
    },
  };
};

export type LocalDatacenterResult = DatacenterResult & {
  datacenterName: string;
};

export type CommercialResult = {
  totalPrice: number;
  monthlyPrice: number;
  teraMonthPrice: number;
};

export type SimulationResult = {
  datacenters: LocalDatacenterResult[];
  datacenterSum: DatacenterResult;

  uploadTime: number;
  downloadTime: number;

  internalCommercial: CommercialResult;
  minimalCommercial: CommercialResult;
  suggestedCommercial: CommercialResult;
};
