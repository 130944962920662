import { atadenAxiosInstance, convertRestPageToDomain, Page, RestPage } from '../../common';
import { useAuthenticatedGet } from '../../common/AuthenticatedRestCall.hook';
import { useCallback } from 'react';
import { UserInfo } from '../domain/UserInfo';
import { User } from '../domain/User';

type RestUserInfo = {
  id: string;
  role: number;
};

type RestUser = {
  id: string;
  email: string;
  creationDate: number;
  role: number;
  licenseApprobationDate: number;
};


const restUserInfoConverter = (userInfo: RestUserInfo): UserInfo => {
  return { ...userInfo };
};

const restUserConverter = (user: RestUser): User => {
  return { ...user };
};

const convertRestPageUserToDomain = (rest: RestPage<RestUser>) => convertRestPageToDomain<RestUser, User>(rest, restUserConverter);

export const useGetCurrentUserInfo = () => {
  const authenticatedGet = useAuthenticatedGet<RestUserInfo, UserInfo>(atadenAxiosInstance, restUserInfoConverter);
  return useCallback(() => authenticatedGet('/protected/api/v1/users/me'), [authenticatedGet]);
};

export const useGetUsers = () => {
  const protectedGet = useAuthenticatedGet<RestPage<RestUser>, Page<User>>(atadenAxiosInstance, convertRestPageUserToDomain);

  return (pageNumber: number): Promise<Page<User>> => {
    return new Promise<Page<User>>((success, failure) => {

      protectedGet('/backoffice/api/v1/users?pageSize=100&pageNumber=' + pageNumber)
        .then(users => {
          success(users);
        })
        .catch(failure);
    });
  };
};

export const useFindUserById = () => {
  const protectedGet = useAuthenticatedGet<RestUser, User>(atadenAxiosInstance, restUserConverter);

  return (userId: string): Promise<User> => {
    return new Promise<User>((success, failure) => {

      protectedGet('/backoffice/api/v1/users/' + userId)
        .then(users => {
          success(users);
        })
        .catch(failure);
    });
  };
};

export const useSearchUser = () => {
  const protectedGet = useAuthenticatedGet<RestUser, User>(atadenAxiosInstance, restUserConverter);

  return (email: string): Promise<User> => {
    return new Promise<User>((success, failure) => {

      protectedGet('/backoffice/api/v1/users/search?email=' + email)
        .then(users => {
          success(users);
        })
        .catch(failure);
    });
  };
};
