import { useFindUserById, useGetUsers, useSearchUser } from '../repositories/UserRepository';
import { User } from '../domain/User';
import React, { useEffect, useState } from 'react';
import { AtdnText, Colors, spacing } from '@ataden/design-system';
import styled from 'styled-components';
import { UserPreview } from '../components/UserPreview';
import { UserPanel } from '../components/UserPanel';
import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from '../../AuthenticatedRouter';

const UserHeaderContainer = styled.div`
    display: grid;
    grid-template-columns: 5px 350px minmax(0px, 1fr) 110px;
    column-gap: 16px;

    width: 100%;
    padding: ${spacing(5)};
    margin-bottom: ${spacing(4)};
    border-bottom: 1px solid ${Colors.Gray100};
`;

export const UserPage = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const getUsers = useGetUsers();
  const findUserById = useFindUserById();
  const searchUser = useSearchUser();

  const [users, updateUsers] = useState<User[]>([]);
  const [currentUser, updateCurrentUser] = useState<User>();

  useEffect(() => {
    getUsers(0)
      .then(pageUser => updateUsers(pageUser.items));
  }, []);

  useEffect(() => {
    const queryParams = location.search.substring(1).split('&');

    let result = queryParams.filter(item => item.startsWith('userId='));
    if (result.length > 0) {
      const userId = result[0].replace('userId=', '');
      findUserById(userId)
        .then(updateCurrentUser);
      return;
    }

    result = queryParams.filter(item => item.startsWith('email='));
    if (result.length > 0) {
      const email = result[0].replace('email=', '');
      searchUser(email)
        .then(updateCurrentUser);
    } else {
      updateCurrentUser(undefined);
    }

  }, [location]);

  const onUserClick = (user: User) => {
    if (user.id === currentUser?.id) {
      navigate(RoutePath.users);
    } else {
      navigate(RoutePath.users + '?userId=' + user.id, { replace: true });
    }
  };

  return (
        <div>
            <UserHeaderContainer>
                <div/>
                <AtdnText type="important">name</AtdnText>
                <AtdnText type="important">email</AtdnText>
                <AtdnText type="important">creation date</AtdnText>
            </UserHeaderContainer>
            {users.map((user, idx) => (
                <UserPreview key={'_' + idx} user={user}
                             onClick={() => onUserClick(user)}/>
            ))}
            {currentUser &&
                <UserPanel user={currentUser}
                           onClose={() => updateCurrentUser(undefined)}/>}
        </div>
  );
};
