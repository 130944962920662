import { DatacenterResult } from '../domain/Simulation';
import styled from 'styled-components';
import { AtdnText } from '@ataden/design-system';
import { SimulationItem } from './SimulationItem';

type DatacenterSimulationProps = {
  name: string;
  datacenter: DatacenterResult
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 0;
`;

const DatacenterName = styled(AtdnText)`
    margin-bottom: 8px;
`;

const Section = styled(AtdnText)`
    margin: 8px 0;
`;


export const DatacenterSimulation = ({ name, datacenter }: DatacenterSimulationProps) => {
  const serverColor = '#319c2e';
  const networkColor = '#2e7f9c';
  const rackColor = '#2e339c';
  const generalColor = '#672e9c';
  return (
        <Container>
            <DatacenterName scale={6}>{name}</DatacenterName>

            <Section scale={5} color={serverColor}>Servers</Section>
            <SimulationItem label="storageServerCount:"
                            value={datacenter.serverResult.storageServerCount.toString()}
                            color={serverColor}/>

            <SimulationItem label="computingServerCount:"
                            value={datacenter.serverResult.computingServerCount.toString()}
                            color={serverColor}/>

            <SimulationItem label="totalServerCost:"
                            value={datacenter.serverResult.totalServerCost.toString() + ' €'}
                            color={serverColor}/>


            <Section scale={5} color={networkColor}>Network</Section>

            <SimulationItem label="storageSwitchCount:"
                            value={datacenter.networkResult.storageSwitchCount.toString()}
                            color={networkColor}/>

            <SimulationItem label="computingSwitchCount:"
                            value={datacenter.networkResult.computingSwitchCount.toString()}
                            color={networkColor}/>

            <SimulationItem label="totalSwitchCost:"
                            value={datacenter.networkResult.totalSwitchCost.toString() + ' €'}
                            color={networkColor}/>

            <SimulationItem label="bandwidthCost:"
                            value={datacenter.networkResult.bandwidthCost.toString() + ' €'}
                            color={networkColor}/>

            <SimulationItem label="networkCost:"
                            value={datacenter.networkResult.networkCost.toString() + ' €'}
                            color={networkColor}/>

            <Section scale={5} color={rackColor}>Rack</Section>

            <SimulationItem label="rackCount:"
                            value={datacenter.rackResult.rackCount.toString()}
                            color={rackColor}/>

            <SimulationItem label="totalRackCost:"
                            value={datacenter.rackResult.totalRackCost.toString() + ' €'}
                            color={rackColor}/>

            <Section scale={5} color={generalColor}>General</Section>

            <SimulationItem label="totalCost:"
                            value={datacenter.generalResult.totalCost.toString() + ' €'}
                            color={generalColor}/>
        </Container>
  );
};
