import styled from 'styled-components';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchLogs } from '../repositories/LogRepository.hook';
import { LogsDisplayer } from './components/LogsDisplayer';
import { Log } from '../domain/Log';
import { LogFilter } from './components/LogFilter';
import { LogRequest } from '../domain/LogRequest';
import { PageSelector } from '../../common/components/PageSelector';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const computePageCount = (totalElements: number, pageSize: number): number => {
  if (totalElements === 0) {
    return 0;
  }

  return Math.max(0, Math.ceil(totalElements / pageSize));
};


const LOG_PAGE_SIZE: number = 50;

export const LogsPage = () => {

  const searchLogs = useSearchLogs();

  const [logs, updateLogs] = useState<Log[]>([]);
  const [pageNumber, updatePageNumber] = useState<number>(0);
  const [pageCount, updatePageCount] = useState<number>(0);

  const [request, updateRequest] = useState<LogRequest>({});

  const onSearch = useCallback(() => {
    searchLogs(request, pageNumber, LOG_PAGE_SIZE)
      .then((result) => {
        const computedPageCount = computePageCount(result.totalElements, LOG_PAGE_SIZE);
        updatePageCount(computedPageCount);

        updateLogs(result.items);
      })
      .catch((failure: any) => {
        console.log('failure', failure);
      });
  }, [pageNumber, request]);

  const onLogPageSelection = (pageNumberValue: number) => {
    updatePageNumber(pageNumberValue);
  };

  const onCorrelationSelection = (correlationId: string | undefined) => {
    console.log('updated !');
    updateRequest({
      ...request,
      correlationId: correlationId,
    });
  };

  useEffect(() => {
    onSearch();
  }, [onSearch]);

  return (
        <Container>
            <LogFilter request={request} onRequestChanged={updateRequest}/>
            <PageSelector pageCount={pageCount} currentPage={pageNumber} onPageSelection={onLogPageSelection}/>
            <LogsDisplayer logs={logs} onCorrelationSelection={onCorrelationSelection}/>
            <PageSelector pageCount={pageCount} currentPage={pageNumber} onPageSelection={onLogPageSelection}/>
        </Container>
  );
};
