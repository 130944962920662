import { AtdnInput, Colors } from '@ataden/design-system';
import { useState } from 'react';
import { simulate } from '../utils/Simulate';
import { SimulationResult } from '../domain/Simulation';
import styled from 'styled-components';
import { DatacenterSimulation } from '../components/DatacenterSimulation';
import { SimulationItem } from '../components/SimulationItem';
import { CommercialSimulation } from '../components/CommercialSimulation';

const InputLine = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;

    width: 100%;
`;

const ResultContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 48px;
`;

export const SimulatorPage = () => {

  const [storageRequested, updateStorageRequested] = useState<number>(400);
  const [monthCommitment, updateMonthCommitment] = useState<number>(12);
  const [bandwidthType, updateBandwidthType] = useState<string>('bp100M');
  const [simulationResult, updateSimulationResult] = useState<SimulationResult>(simulate(storageRequested, monthCommitment, bandwidthType));

  const onStorageRequestedChanged = (value: number) => {
    updateStorageRequested(value);

    updateSimulationResult(simulate(value, monthCommitment, bandwidthType));
  };

  const onMonthCommitmentChanged = (value: number) => {
    updateMonthCommitment(value);

    updateSimulationResult(simulate(storageRequested, value, bandwidthType));
  };

  const onBandwidthTypeChanged = (value: string) => {
    updateBandwidthType(value);

    updateSimulationResult(simulate(storageRequested, monthCommitment, value));
  };

  return (
        <div>
            <div>{'Simulation > 50 TB'}</div>

            <InputLine>
                <AtdnInput label="Storage requested (To)" value={storageRequested.toString()}
                           onChange={(evt) => onStorageRequestedChanged(+evt.target.value)}/>
                <AtdnInput label="Commitment (Month)" value={monthCommitment.toString()} type="number"
                           onChange={(evt) => onMonthCommitmentChanged(+evt.target.value)}/>
                <select onChange={(evt) => onBandwidthTypeChanged(evt.target.value)}>
                    <option value="bp100M">100Mb/s</option>
                    <option value="bp1G">1Gb/s</option>
                    <option value="bp10G">10Gb/s</option>
                </select>
            </InputLine>

            {simulationResult && (
                <div>
                    <ResultContainer>
                        {simulationResult.datacenters.map((datacenter) => <DatacenterSimulation
                            key={datacenter.datacenterName} name={datacenter.datacenterName} datacenter={datacenter}/>)}
                        <DatacenterSimulation name="Total" datacenter={simulationResult.datacenterSum}/>
                    </ResultContainer>

                    <SimulationItem label="Time to full upload:"
                                    value={simulationResult.uploadTime.toString() + ' h'}
                                    color={Colors.Purple700}/>

                    <SimulationItem label="Time to full download:"
                                    value={simulationResult.downloadTime.toString() + ' h'}
                                    color={Colors.Purple700}/>
 
                    <ResultContainer>
                        <CommercialSimulation title="internal" commercialResult={simulationResult.internalCommercial}/>
                        <CommercialSimulation title="minimal" commercialResult={simulationResult.minimalCommercial}/>
                        <CommercialSimulation title="suggested"
                                              commercialResult={simulationResult.suggestedCommercial}/>
                    </ResultContainer>
                </div>
            )
            }
        </div>
  )
  ;
};
