import styled from 'styled-components';
import { MenuItem } from './MenuItem';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { AtadenLogo, Colors } from '@ataden/design-system';
import { RoutePath } from '../../AuthenticatedRouter';

const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 230px;
    height: 100vh;
    background-color: ${Colors.Gray000};
    padding: 12px;
`;

const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 16px 0;
`;

const ItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const MiddleContainer = styled.div`
    margin-top: 36px;
    height: 100%;
`;

const BottomContainer = styled.div`
    margin-bottom: 32px;
`;

export const NavigationMenu = () => {
  const logoutRedirection = process.env.REACT_APP__AUTH__LOGOUT!;

  const navigate = useNavigate();
  const { logout } = useAuth0();

  const onLogOut = () => {
    logout({ logoutParams: { returnTo: logoutRedirection } });
  };

  const onProjects = () => {
    navigate(RoutePath.projects);
  };

  const onUsers = () => {
    navigate(RoutePath.users);
  };

  const onEvents = () => {
    navigate(RoutePath.events);
  };

  const onLogs = () => {
    navigate(RoutePath.logs);
  };

  const onIdentity = () => {
    navigate(RoutePath.identity);
  };

  const onCompanyIdentity = () => {
    navigate(RoutePath.companyIdentity);
  };

  const onDomains = () => {
    navigate(RoutePath.domains);
  };

  const onServers = () => {
    navigate(RoutePath.servers);
  };

  const onSimulation = () => {
    navigate(RoutePath.simulator);
  };

  return (
        <Container>
            <LogoContainer>
                <AtadenLogo width="120px" height="40px"/>
            </LogoContainer>
            <ItemsContainer>
                <MiddleContainer>
                    <MenuItem title="Projects" onClick={onProjects}/>
                    <MenuItem title="Users" onClick={onUsers}/>
                    <MenuItem title="Events" onClick={onEvents}/>
                    <MenuItem title="Logs" onClick={onLogs}/>
                    <MenuItem title="Identity" onClick={onIdentity}/>
                    <MenuItem title="Company Identity" onClick={onCompanyIdentity}/>
                    <MenuItem title="Simulation" onClick={onSimulation}/>
                    <MenuItem title="Domains" onClick={onDomains}/>
                    <MenuItem title="Servers" onClick={onServers}/>
                </MiddleContainer>
                <BottomContainer>
                    <MenuItem title="Log out" onClick={onLogOut}/>
                </BottomContainer>
            </ItemsContainer>
        </Container>
  );
};
