import { dateNumberToDateString, timeNumberToTimeString } from '../../common/DateUtils';
import { AtdnText, Colors, Loader } from '@ataden/design-system';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useFetchEventPayload } from '../../events/repositories/EventRepository.hook';

type SupportTicketComponentProps = {
  eventId: string;
  creationDate: number;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 8px;
`;

export const SupportTicketComponent = ({ eventId, creationDate }: SupportTicketComponentProps) => {
  const date = dateNumberToDateString(creationDate, 'DD-MM-YYYY');
  const time = timeNumberToTimeString(creationDate, 'hh:mm:ss.mmm');

  const fetchEventPayload = useFetchEventPayload();

  const [payload, updatePayload] = useState<string>();
  const [origin, updateOrigin] = useState<string>('');
  const [isLoading, updateLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchEventPayload(eventId)
      .then((event) => {
        updatePayload(event.content);
        updateOrigin(event.origin);
      })
      .finally(() => {
        updateLoading(false);
      });
  }, []);

  return (
        <Container>
            <AtdnText color={Colors.Gray500}>{date} {time} ({origin})</AtdnText>
            {isLoading && (
                <Loader scale={1}/>
            )}
            {payload && (
                <AtdnText>{payload}</AtdnText>
            )}
        </Container>
  );
};
