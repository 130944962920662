import { Project } from '../../domain/Project';
import styled from 'styled-components';
import { Colors, spacing } from '@ataden/design-system';
import { dateNumberToDateString, timeNumberToTimeString } from '../../../common/DateUtils';

type ProjectLineProps = {
  project: Project;

  onClick: () => void;
};

const ProjectContainer = styled.div`
    display: grid;
    grid-template-columns: 100px minmax(0px, 1fr) 300px 25px;
    column-gap: 16px;

    width: 100%;
    padding: ${spacing(5)};
    margin-bottom: ${spacing(4)};
    border-bottom: 1px solid ${Colors.Gray100};

    cursor: pointer;
`;

export const ProjectLine = ({ project, onClick }: ProjectLineProps) => {
  const date = dateNumberToDateString(project.createdDate, 'DD-MM-YYYY');
  const time = timeNumberToTimeString(project.createdDate, 'hh:mm:ss.mmm');


  return (<ProjectContainer onClick={onClick}>
        <div/>
        <div>{project.name}</div>
        <div>{date} - {time}</div>
    </ProjectContainer>);
};
