import { controllerAxiosInstance, convertRestPageToDomain, Page, RestPage } from '../../common';
import { Log, LogLevel, ServiceType } from '../domain/Log';
import { useAuthenticatedGet } from '../../common/AuthenticatedRestCall.hook';
import { LogRequest } from '../domain/LogRequest';

type RestLog = {
  serverId: string;
  hostname: string;
  type: ServiceType;
  date: number;
  level: LogLevel;
  message: string;
  stackTrace: string;
  correlationId?: string;
};

const convertRestLogToDomain = (restLog: RestLog): Log => {
  return {
    ...restLog,
  };
};

const convertRestPageLogToDomain = (rest: RestPage<RestLog>) => convertRestPageToDomain<RestLog, Log>(rest, convertRestLogToDomain);

const buildRequestParam = (request: LogRequest): string => {
  let param = '';
  if (request.level) {
    param += '&level=' + request.level;
  }

  if (request.type) {
    param += '&serviceType=' + request.type;
  }

  if (request.serverId) {
    param += '&serverId=' + request.serverId;
  }

  if (request.correlationId) {
    param += '&correlationId=' + request.correlationId;
  }

  if (request.hostname) {
    param += '&hostname=' + request.hostname;
  }

  if (request.dateBefore) {
    param += '&beforeDate=' + request.dateBefore;
  }

  if (request.dateAfter) {
    param += '&afterDate=' + request.dateAfter;
  }

  return param;

};

export const useSearchLogs = () => {
  const protectedGet = useAuthenticatedGet<RestPage<RestLog>, Page<Log>>(controllerAxiosInstance, convertRestPageLogToDomain);

  return (request: LogRequest, pageNumber: number, pageSize: number): Promise<Page<Log>> => {
    const param = buildRequestParam(request);

    return protectedGet('/backoffice/api/v1/logs/search?pageNumber=' + pageNumber + '&pageSize=' + pageSize + param);
  };
};
