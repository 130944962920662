import {
  CommercialResult,
  Datacenter,
  DatacenterResult,
  DeviceType,
  emptyDatacenter,
  LocalDatacenterResult,
  ServerComputing,
  ServerStorage,
  SimulationResult,
  Switch,
} from '../domain/Simulation';

const defaultServerStorage: ServerStorage = {
  type: DeviceType.SERVER_STORAGE,
  price: 767.55,
  storage: 18 * 2,
  heightU: 4,
};

const defaultServerComputing: ServerComputing = {
  type: DeviceType.SERVER_STORAGE,
  price: 119.15,
  heightU: 2,
};

const defaultSwitch: Switch = {
  type: DeviceType.SWITCH,
  price: 203.81 / 1.2,
  heightU: 1,
};

const lyonDatacenter: Datacenter = {
  PUE: 1.4,
  rackCost: 471.5 * 2,
  bp100M: 100,
  bp1G: 450,
  bp10G: 1800,
};

const vlbDatacenter: Datacenter = {
  PUE: 1.5,
  rackCost: 1060,
  bp100M: 200,
  bp1G: 600,
  bp10G: 2000,
};

const defaultBandwidthSpeed: {
  bp100M: number,
  bp1G: number,
  bp10G: number,
} = {
  bp100M: 0.0001,
  bp1G: 0.001,
  bp10G: 0.01,
};

const computeDatacenterMaterial = (datacenterName: string, storageServerCount: number, computingServerCount: number, datacenter: Datacenter, bandwidthType: string, monthCommitment: number): LocalDatacenterResult => {

  // Servers
  const totalServerCost = Math.ceil(storageServerCount * defaultServerStorage.price + computingServerCount * defaultServerComputing.price);

  // Network simulation
  const computingSwitchCount = Math.ceil(computingServerCount / 16);
  const storageSwitchCount = Math.ceil(storageServerCount / 12);
  const switchCost = Math.ceil((computingSwitchCount + storageSwitchCount) * defaultSwitch.price);
  const bandwidthCost = Math.ceil((<any>datacenter)[bandwidthType] * monthCommitment);
  const networkCost = switchCost + bandwidthCost;

  // Rack simulation
  const computingStageCount = Math.ceil(computingSwitchCount / 2) * defaultServerComputing.heightU;
  const storageStageCount = Math.ceil(storageServerCount / 12) * defaultServerStorage.heightU;
  const rackCount = Math.ceil((computingStageCount + storageStageCount) / 20);
  const rackCost = Math.ceil(rackCount * datacenter.rackCost * monthCommitment);

  const totalCost = totalServerCost + networkCost + rackCost;

  return {
    datacenterName: datacenterName,

    serverResult: {
      storageServerCount: storageServerCount,
      computingServerCount: computingServerCount,
      totalServerCost: totalServerCost,
    },

    networkResult: {
      storageSwitchCount: storageSwitchCount,
      computingSwitchCount: computingSwitchCount,
      totalSwitchCost: switchCost,
      bandwidthCost: bandwidthCost,
      networkCost: networkCost,
    },

    rackResult: {
      rackCount: rackCount,
      totalRackCost: rackCost,
    },

    generalResult: {
      totalCost: totalCost,
    },
  };
};

const sumDatacenters = (datacenters: DatacenterResult[]): DatacenterResult => {
  const result: DatacenterResult = emptyDatacenter();

  datacenters.forEach((datacenter) => {
    result.serverResult.storageServerCount += datacenter.serverResult.storageServerCount;
    result.serverResult.computingServerCount += datacenter.serverResult.computingServerCount;
    result.serverResult.totalServerCost += datacenter.serverResult.totalServerCost;

    result.networkResult.storageSwitchCount += datacenter.networkResult.storageSwitchCount;
    result.networkResult.computingSwitchCount += datacenter.networkResult.computingSwitchCount;
    result.networkResult.totalSwitchCost += datacenter.networkResult.totalSwitchCost;
    result.networkResult.bandwidthCost += datacenter.networkResult.bandwidthCost;
    result.networkResult.networkCost += datacenter.networkResult.networkCost;

    result.rackResult.rackCount += datacenter.rackResult.rackCount;
    result.rackResult.totalRackCost += datacenter.rackResult.totalRackCost;

    result.generalResult.totalCost += datacenter.generalResult.totalCost;
  });

  return result;
};

const toCents = (value: number): number => {
  return Math.ceil(value * 100) / 100;
};

const computeCommercialResult = (totalCost: number, storageRequested: number, monthCommitment: number): CommercialResult => {
  const totalPrice = toCents(totalCost);
  const monthlyPrice = toCents(totalCost / monthCommitment);
  const teraMonthPrice = toCents(monthlyPrice / storageRequested);
  return {
    totalPrice: totalPrice,
    monthlyPrice: monthlyPrice,
    teraMonthPrice: teraMonthPrice,
  };
};

export const simulate = (storageRequested: number, monthCommitment: number, bandwidthType: string): SimulationResult => {

  const datacenterResult = [];

  const requiredStorage = storageRequested * 3;

  // Material simulation
  const storageServerCount = Math.ceil(requiredStorage / defaultServerStorage.storage);
  const lyonStorageServerCount = Math.ceil(storageServerCount * 2 / 3);
  const computingServerCount = Math.ceil(storageServerCount / 6 * 8);
  const lyonComputingServerCount = Math.ceil(computingServerCount * 2 / 3);

  datacenterResult.push(computeDatacenterMaterial('Lyon', lyonStorageServerCount, lyonComputingServerCount, lyonDatacenter, bandwidthType, monthCommitment));
  datacenterResult.push(computeDatacenterMaterial('Villeurbanne', storageServerCount - lyonStorageServerCount, computingServerCount - lyonComputingServerCount, vlbDatacenter, bandwidthType, monthCommitment));

  const datacenterSum = sumDatacenters(datacenterResult);

  // General
  const uploadTime = toCents(storageRequested / (<any>defaultBandwidthSpeed)[bandwidthType] / 3600);
  const downloadTime = uploadTime;

  // Financial simulation
  const totalPrice = datacenterSum.generalResult.totalCost;
  const internalCommercial = computeCommercialResult(totalPrice, storageRequested, monthCommitment);
  const minimalCommercial = computeCommercialResult(totalPrice * 1.15, storageRequested, monthCommitment);
  const suggestedCommercial = computeCommercialResult(totalPrice * 1.25, storageRequested, monthCommitment);

  return {
    datacenters: datacenterResult,
    datacenterSum: datacenterSum,
    uploadTime: uploadTime,
    downloadTime: downloadTime,

    internalCommercial: internalCommercial,
    minimalCommercial: minimalCommercial,
    suggestedCommercial: suggestedCommercial,
  };
};
