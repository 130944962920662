import { User } from '../domain/User';
import styled from 'styled-components';
import { Colors, spacing } from '@ataden/design-system';
import { dateNumberToDateString } from '../../common/DateUtils';

type UserPreviewProps = {
  user: User;

  onClick: () => void;
};

const UserContainer = styled.div`
    display: grid;
    grid-template-columns: 5px 350px minmax(0px, 1fr) 110px;
    column-gap: 16px;

    width: 100%;
    padding: ${spacing(5)};
    margin-bottom: ${spacing(4)};
    border-bottom: 1px solid ${Colors.Gray100};

    cursor: pointer;
`;

export const UserPreview = ({ user, onClick }: UserPreviewProps) => {
  const dateMilli = user.creationDate;
  const time = dateNumberToDateString(dateMilli, 'DD-MM-YYYY');

  return (
        <UserContainer onClick={onClick}>
            <div/>
            <div>{user.id}</div>
            <div>{user.email}</div>
            <div>{time}</div>
        </UserContainer>
  );
};
