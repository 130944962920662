import { CommercialResult } from '../domain/Simulation';
import styled from 'styled-components';
import { AtdnText } from '@ataden/design-system';
import { SimulationItem } from './SimulationItem';

type CommercialSimulationProps = {
  title: string;
  commercialResult: CommercialResult;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 0;
`;

const Title = styled(AtdnText)`
    margin-bottom: 8px;
`;


export const CommercialSimulation = ({ title, commercialResult }: CommercialSimulationProps) => {
  const commercialColor = '#9c2e69';
  return (
        <Container>
            <Title scale={6}>{title}</Title>

            <SimulationItem label="totalPrice:"
                            value={commercialResult.totalPrice.toString() + ' €'}
                            color={commercialColor}/>

            <SimulationItem label="monthlyPrice:"
                            value={commercialResult.monthlyPrice.toString() + ' €'}
                            color={commercialColor}/>

            <SimulationItem label="teraMonthPrice:"
                            value={commercialResult.teraMonthPrice.toString() + ' €'}
                            color={commercialColor}/>
        </Container>
  );
};
