import { AtdnButton, AtdnInput } from '@ataden/design-system';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

type CorrelationFilterProps = {
  correlationId?: string;
  onChange: (correlationId: string | undefined) => void;
};

const Container = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    gap: 16px;
`;

const Btn = styled(AtdnButton)`
    height: fit-content;
`;

export const CorrelationFilter = ({ correlationId, onChange }: CorrelationFilterProps) => {

  const [currentCorrelation, updateCurrentCorrelation] = useState<string>(correlationId ?? '');

  useEffect(() => {
    updateCurrentCorrelation(correlationId ?? '');
  }, [correlationId]);

  const onUpdate = () => {
    const corr = currentCorrelation.trim();
    if (corr.length === 0) {
      onChange(undefined);
      updateCurrentCorrelation('');
    } else {
      onChange(corr);
    }
  };

  const onClear = () => {
    const corr = currentCorrelation.trim();
    if (corr.length === 0) {
      return;
    }

    onChange(undefined);
    updateCurrentCorrelation('');
  };

  return (
        <Container>
            <AtdnInput label="Correlation Id" value={currentCorrelation}
                       onChange={(evt) => updateCurrentCorrelation(evt.target.value)}/>
            <Btn onClick={onUpdate}>Update correlation</Btn>
            {correlationId && correlationId.length > 0 && (<Btn onClick={onClear}>Clear correlation</Btn>)}
        </Container>
  );
};
