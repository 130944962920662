import { Project } from '../../domain/Project';
import styled from 'styled-components';
import { AtdnText, Colors, CrossIcon } from '@ataden/design-system';
import { dateNumberToDateString, timeNumberToTimeString } from '../../../common/DateUtils';
import { InfoContainer, ItemContainer, LabelContainer } from '../../../common/components/PanelItem.shared';
import { StorageQuotaLimit } from './StorageQuotaLimit';

type ProjectPanelProps = {
  project: Project;
  onProjectUpdated: (project: Project) => void;

  onClose: () => void;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;

    padding: 16px;
    max-width: 420px;

    background-color: ${Colors.White};
    box-shadow: ${Colors.Gray300} -1px 1px 5px 1px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 32px;
`;
const CloseIconWrapper = styled.div`
    width: 18px;
    height: 18px;
    cursor: pointer;
`;


export const ProjectPanel = ({ project, onProjectUpdated, onClose }: ProjectPanelProps) => {
  const date = dateNumberToDateString(project.createdDate, 'DD-MM-YYYY');
  const time = timeNumberToTimeString(project.createdDate, 'hh:mm:ss.mmm');

  return (
        <Container>
            <Header>
                <AtdnText type="important">{project.name}</AtdnText>
                <CloseIconWrapper onClick={onClose}>
                    <CrossIcon color={Colors.Brown700}/>
                </CloseIconWrapper>
            </Header>
            <ItemContainer>
                <InfoContainer>
                    <LabelContainer>
                        <AtdnText type="important">id:</AtdnText>
                        <AtdnText>{project.id}</AtdnText>
                    </LabelContainer>
                </InfoContainer>
            </ItemContainer>
            <ItemContainer>
                <InfoContainer>
                    <LabelContainer>
                        <AtdnText type="important">creation date:</AtdnText>
                        <AtdnText>{date} {time}</AtdnText>
                    </LabelContainer>
                </InfoContainer>
            </ItemContainer>
            <StorageQuotaLimit project={project} onProjectUpdated={onProjectUpdated}/>
        </Container>
  );
};
